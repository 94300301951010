import React from 'react';
import {connect} from 'react-redux'
import {userSignOut} from 'actions/Auth';
import {LaCon} from 'constants/Links';

class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };

  render() {
    return (
      <div style={{overflow: "hidden"}}className="user-profile d-flex flex-row align-items-center">
        <img src={require('assets/images/logo2.png')}
          style={{width: "100%"}}
          alt='Doe Vidas'
        />
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {locale} = settings;
  return {locale}
};
export default connect(mapStateToProps, {userSignOut})(UserInfo);


