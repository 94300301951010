import React from 'react';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { LaCon } from '../../constants/Links';

class ApoioUsuarioSide extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            isOpen:false,
        }
    }
    render() { 
        const { isOpen } = this.state;
        return (<li className={`menu ${isOpen ? 'open':''} collapse-box`}>
            <Button onClick={()=>this.setState({isOpen: !isOpen})}>
                <i className="zmdi zmdi-pin-help zmdi-hc-fw"/>
                <span className="nav-text">Apoio ao Usuário</span>
            </Button>
            <ul className="sub-menu">
                <li>
                    <NavLink to='#' className="prepend-icon" onClick={()=>{window.open(`${LaCon}api/manual/unidade`)}}>
                        <i className="zmdi zmdi-pin-assistant zmdi-hc-fw"/>
                        <span className="nav-text">Manual do usuário</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="prepend-icon" to={`/app/apoio/suporte`}>
                        <i className="zmdi zmdi-pin-assistant zmdi-hc-fw"/>
                        <span className="nav-text">Contate o Suporte</span>
                    </NavLink>
                </li>
            </ul>
        </li>);
    }
}
 
export default ApoioUsuarioSide;